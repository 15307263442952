<template>
  <div :class="[labeltype == 'none' ? 'form-entry-container-none' : 'form-entry-container']" :id="component">
    <div v-if="labeltype == 'none'">
      {{ title }}
    </div>
    <div class="form-entry-label" :style="labelstyle" v-else-if="labeltype != 'none'">
      {{ title }}
    </div>
    <div class="form-entry-input">
      <div v-if="type == 'surveyViewer'" style="display: flex; flex-direction: row;">
        <div id="SurveyViewerContainer" style="width: calc(100% - 120px);"></div>
      </div>
      <div v-if="type == 'surveyEditor'" style="display: flex; flex-direction: row;">
        <div id="SurveyEditorContainer" style="width: calc(100% - 120px);"></div>
      </div>
      <div>Surveyjs Version 2</div>
    </div>
  </div>
</template>

<script>

import SurveyTemplateCompleteEditor from '@/assets/data/SurveyTemplateCompleteEditor.json'
import { SurveyCreator } from 'survey-creator-knockout'

export default {
  props: ['name', 'title', 'type', 'size', 'datasource', 'textfield', 'valuefield', 'component', 'value', 'text', 'cols', 'rows', 'labelstyle', 'labeltype', 'inputstyle', 'palettearray', 'filter', 'note', 'autowidth'],
  data () {
    return {
      surveyTemplateCompleteEditor: SurveyTemplateCompleteEditor
    }
  },
  mounted: function () {
    var surveyViewerOptions = {
      showLogicTab: false,
      showDesignerTab: true,
      showPreviewTab: true,
      showJSONEditorTab: false,
      showSidebar: true,
      questionTypes: ['text', 'checkbox', 'radiogroup', 'dropdown']
    }
    var surveyEditorOptions = {
      showLogicTab: false,
      showDesignerTab: true,
      showPreviewTab: true,
      showJSONEditorTab: false,
      showSidebar: true,
      questionTypes: ['text', 'checkbox', 'radiogroup', 'dropdown']
    }
    if (this.type === 'surveyViewer') {
      const creator = new SurveyCreator(surveyViewerOptions)
      creator.render('SurveyViewerContainer')
      creator.text = JSON.stringify(this.surveyTemplateCompleteEditor)
      creator.themeForPreview('modern')
      creator.toolbox.forceCompact = false
    }
    if (this.type === 'surveyEditor') {
      const creator = new SurveyCreator(surveyEditorOptions)
      creator.render('SurveyEditorContainer')
      creator.text = JSON.stringify(this.surveyTemplateCompleteEditor)
      creator.allowAddToToolbox = true
      creator.survey.title = ' '
      creator.survey.description = ' '
      // creator.survey.
      creator.toolbox.forceCompact = false
    }
  }
}
</script>

<style scoped>
.k-grid .k-header {
   display: none !important;
}

.svc-string-editor {
  color: red !important;
}

.svd-sidebar-title {
    font-size: 20px;
    padding: 10px 30px;
    color: blue;
}

#SurveyViewerContainer {
  height: 100vh;
  width: 100vw;
}
</style>
